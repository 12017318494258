import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { BrandNameComponent } from '@layouts/dashboard-layout/_components/brand-name/brand-name.component';
import { NavigationListItemComponent } from '@layouts/dashboard-layout/_components/navigation-list-item/navigation-list-item.component';
import { sidebarConfig } from '@layouts/dashboard-layout/_configs/sidebar.config';
import { NavigationListItem } from '@layouts/dashboard-layout/_interfaces/navigation-list-item.interface';
import { DashboardLayoutService } from '@layouts/dashboard-layout/_services/dashboard-layout.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, BrandNameComponent, NavigationListItemComponent],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  readonly dashboardLayoutService = inject(DashboardLayoutService);
  readonly storageService = inject(StorageService);

  navigationListItems: NavigationListItem[] = [];
  logoutNavigationItem?: NavigationListItem;

  constructor() {
    const isAgent = this.storageService.getUser()?.is_agent === 1;
    const userId = this.storageService.getUser()?.id;

    this.navigationListItems = sidebarConfig(userId && isAgent ? userId.toString() : null).navigationListItems;
    this.logoutNavigationItem = sidebarConfig(userId && isAgent ? userId.toString() : null).logoutNavigationItem;
  }
}
