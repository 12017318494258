import { GetNotificationsResponse } from 'types/responses/get-notifications-response.type';

export const USER_NOTIFICATIONS_CONFIG: {
  notificationsResponse: GetNotificationsResponse;
  isOnlyUnreadChecked: boolean;
} = {
  notificationsResponse: {
    data: [],
    uncompleted_notifications: 0,
    meta: {
      current_page: 1,
      per_page: 25,
      total: 0,
    },
  },
  isOnlyUnreadChecked: true,
};
