import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LOGO_PATH, PLACEHOLDER_IMAGE_128x128 } from '@configs/constants.config';
import { AppRoutes } from '@configs/routes.config';
import { environment } from '@environment';
import { TranslocoPipe } from '@jsverse/transloco';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@Component({
  selector: 'app-brand-name',
  standalone: true,
  imports: [LazyLoadImageModule, RouterLink, TranslocoPipe],
  templateUrl: './brand-name.component.html',
  styleUrl: './brand-name.component.scss',
})
export class BrandNameComponent {
  protected readonly AppRoutes = AppRoutes;
  protected readonly PLACEHOLDER_IMAGE_128x128 = PLACEHOLDER_IMAGE_128x128;
  protected readonly LOGO_PATH = LOGO_PATH;
  protected readonly appVersion = environment.appVersion;
}
