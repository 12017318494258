import { Component, computed, input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ticketsCounter } from '@helpers/echo-cache.helper';
import { TranslocoPipe } from '@jsverse/transloco';
import { NAVIGATION_LIST_ITEM_CONFIG } from '@layouts/dashboard-layout/_configs/navigation-list-item.config';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { NavigationListItem } from '../../_interfaces/navigation-list-item.interface';

const EXACT_ROUTE_OPTIONS = { exact: true };
const DEFAULT_ROUTE_OPTIONS = { exact: false };

@Component({
  selector: 'app-navigation-list-item',
  standalone: true,
  imports: [RouterLink, RouterLinkActive, TranslocoPipe, NgIconComponent],
  providers: [provideIcons(NAVIGATION_LIST_ITEM_CONFIG.icons)],
  templateUrl: './navigation-list-item.component.html',
  styleUrl: './navigation-list-item.component.scss',
})
export class NavigationListItemComponent {
  item = input.required<NavigationListItem>();
  unreadTicketsCount = computed(() => ticketsCounter());

  getRouterLinkActiveOptions(): { exact: boolean } {
    return this.item().isNotExactRoute ? DEFAULT_ROUTE_OPTIONS : EXACT_ROUTE_OPTIONS;
  }
}
