import { ChangeDetectionStrategy, Component, computed, inject, model, ModelSignal } from '@angular/core';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { tablerEye, tablerFlare, tablerMessage } from '@ng-icons/tabler-icons';
import { Notification } from 'types/notification.type';

import { DATE_TIME_FORMAT_WITH_SEPARATOR } from '@configs/dates.config';
import { NotificationAction } from '@enums/notification-action.enum';
import { TranslocoService } from '@jsverse/transloco';
import { UserAvatarComponent } from '@components/user-avatar/user-avatar.component';
import { UserHelper } from '@helpers/user.helper';
import { UserNotificationsService } from '@layouts/dashboard-layout/_services/user-notifications.service';
import { NotificationState } from '@layouts/dashboard-layout/_enums/notification-state.enum';
import { FormatDateToFormat } from '@helpers/format-date-to-format.helper';

@Component({
  selector: 'app-user-notification',
  standalone: true,
  imports: [NgIcon, UserAvatarComponent],
  templateUrl: './user-notification.component.html',
  styleUrl: './user-notification.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideIcons({ tablerEye, tablerFlare, tablerMessage })],
})
export class UserNotificationComponent {
  private readonly translocoService = inject(TranslocoService);
  private readonly userNotificationsService = inject(UserNotificationsService);

  protected readonly NotificationState = NotificationState;
  protected readonly NotificationAction = NotificationAction;
  protected readonly UserHelper = UserHelper;

  notification = model.required<Notification>();

  title = computed<string>(() => {
    switch (this.notification()!.action) {
      case NotificationAction.TICKET_ASSIGNED:
        return `#${this.notification()!.notifiable.id} ${this.translocoService.translate('_Notifications.New ticket')}`;
      case NotificationAction.COMMENT_CREATED:
        return `#${this.notification()!.notifiable.id} ${this.translocoService.translate('_Notifications.New comment')}`;
      default:
        return this.translocoService.translate('_Notifications.New notification');
    }
  });

  description = computed<string>(() => {
    const issuer = this.notification()!.issuer
      ? UserHelper.getFullName(this.notification()!.issuer!)
      : this.translocoService.translate('_Notifications.System');

    switch (this.notification()!.action) {
      case NotificationAction.TICKET_ASSIGNED:
        return this.translocoService.translate('_Notifications.There is a new ticket assigned to you', { issuer });
      case NotificationAction.COMMENT_CREATED:
        return this.translocoService.translate('_Notifications.There is a new comment on ticket', {
          id: this.notification()!.notifiable.id,
          issuer,
        });
      default:
        return this.translocoService.translate('_Notifications.Notification has been created');
    }
  });

  date = computed<string>(() => FormatDateToFormat(this.notification()!.created_at, DATE_TIME_FORMAT_WITH_SEPARATOR));

  markAsReadHandler(): void {
    !this.notification()!.completed_at && this.userNotificationsService.markAsRead(this.notification as ModelSignal<Notification>);
  }
}
