<li class="navigation-list-item">
  <a
    [routerLinkActiveOptions]="getRouterLinkActiveOptions()"
    [routerLink]="item().route"
    [queryParams]="item().queryParams"
    [queryParamsHandling]="item().queryParamsHandling"
    class="navigation-list-item__link"
    routerLinkActive="active">
    <span class="link__icon">
      @if (item().hasCounter && unreadTicketsCount()) {
        <div class="notification-badge">
          {{ unreadTicketsCount() }}
        </div>
      }
      <ng-icon [name]="item().icon"></ng-icon>
    </span>
    <span class="link__title">{{ '_DashboardLayoutSidebar.' + item().title | transloco }}</span>
  </a>
</li>
