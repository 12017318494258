import { AppRoutes } from '@configs/routes.config';
import { TicketsFiltersFormControls } from '@enums/form-controls.enum';
import { NavigationListItem } from '@layouts/dashboard-layout/_interfaces/navigation-list-item.interface';

export const sidebarConfig: (agentId: string | null) => {
  navigationListItems: NavigationListItem[];
  logoutNavigationItem: NavigationListItem;
} = (agentId: string | null) => ({
  navigationListItems: [
    {
      title: 'Dashboard',
      icon: 'tablerLayoutDashboard',
      route: `${AppRoutes.Dashboard}`,
    },
    {
      title: 'Tickets',
      icon: 'tablerTicket',
      route: AppRoutes.Tickets.Root,
      queryParamsHandling: 'merge',
      queryParams: { page: 1, per_page: 10, [TicketsFiltersFormControls.AGENT]: agentId ? agentId : null },
      hasCounter: true,
      isNotExactRoute: true,
    },
    {
      title: 'Statistics',
      icon: 'tablerChartDots',
      route: `${AppRoutes.Statistics}`,
    },
    {
      title: 'Agents',
      icon: 'tablerUser',
      route: AppRoutes.Agents.Root,
      queryParams: { page: 1, per_page: 10 },
      isNotExactRoute: true,
    },
    {
      title: 'Settings',
      icon: 'tablerSettings',
      route: AppRoutes.Settings,
      isNotExactRoute: true,
    },
  ],

  logoutNavigationItem: {
    title: 'Logout',
    icon: 'tablerPower',
  },
});
