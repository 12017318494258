<div class="notification" (click)="markAsReadHandler()">
  <div class="notification__icon">
    @if (notification().issuer) {
      <app-user-avatar size="large" [user]="notification()!.issuer"
                       [showIndicator]="UserHelper.isClient(notification()!.issuer!)" />
    } @else {
      <div class="notification__icon-wrapper">
        @switch (notification().action) {
          @case (NotificationAction.TICKET_ASSIGNED) {
            <ng-icon name="tablerFlare" strokeWidth="1.25" />
          }
          @default {
            <ng-icon name="tablerMessage" strokeWidth="1.25" />
          }
        }
      </div>
    }
  </div>
  <div class="notification__content">
    <div class="notification__content-header"
         [attr.data-state]="notification().completed_at ? NotificationState.READ : NotificationState.UNREAD">
      <div class="notification__content-header-title">
        {{ title() }}
      </div>
      <ng-icon name="tablerEye" strokeWidth="1.25" />
    </div>
    <div class="notification__content-description">
      {{ description() }}
    </div>
    <div class="notification__content-time">
      {{ date() }}
    </div>
  </div>
</div>
