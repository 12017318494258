import {
  tablerChartDots,
  tablerLayoutDashboard,
  tablerPower,
  tablerSettings,
  tablerTicket,
  tablerUser,
} from '@ng-icons/tabler-icons';

export const NAVIGATION_LIST_ITEM_CONFIG: {
  icons: { [key: string]: string };
} = {
  icons: {
    tablerLayoutDashboard,
    tablerTicket,
    tablerChartDots,
    tablerSettings,
    tablerPower,
    tablerUser,
  },
};
