import { computed, DestroyRef, inject, Injectable, ModelSignal, signal, WritableSignal } from '@angular/core';
import { USER_NOTIFICATIONS_CONFIG } from '@layouts/dashboard-layout/_configs/user-notifications.config';
import { NotificationsService } from '@services/notifications.service';
import { ToastService } from '@services/toast.service';
import { ErrorService } from '@services/error.service';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { DateTime } from 'luxon';
import { Notification } from 'types/notification.type';

import { GetNotificationsResponse } from 'types/responses/get-notifications-response.type';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { mergeMap } from 'rxjs';
import { EchoChannel } from '@enums/echo-channel.enum';
import { EchoEvent } from '@enums/echo-event.enum';
import { EchoEventData } from '@interfaces/echo-event-data.interface';
import { EchoService } from '@services/echo.service';
import { StorageService } from '@services/storage.service';

@Injectable()
export class UserNotificationsService {
  private readonly destroyRef = inject(DestroyRef);
  private readonly notificationsService = inject(NotificationsService);
  private readonly toastService = inject(ToastService);
  private readonly errorService = inject(ErrorService);
  private readonly echo = inject(EchoService);
  private readonly storageService = inject(StorageService);

  loading = signal<boolean>(true);
  notificationsResponse = signal<GetNotificationsResponse>(USER_NOTIFICATIONS_CONFIG.notificationsResponse);
  notifications = signal<Notification[]>([]);
  uncompletedNotificationsCount = signal<number>(0);
  previousOnlyUnread = signal<boolean>(USER_NOTIFICATIONS_CONFIG.isOnlyUnreadChecked);

  todayNotifications = computed<Notification[]>(() =>
    this.notifications().filter(notification => DateTime.now().diff(DateTime.fromJSDate(new Date(notification.created_at)), 'days').days <= 1)
  );

  yesterdayNotifications = computed<Notification[]>(() =>
    this.notifications().filter(
      notification =>
        DateTime.now().diff(DateTime.fromJSDate(new Date(notification.created_at)), 'days').days > 1 &&
        DateTime.now().diff(DateTime.fromJSDate(new Date(notification.created_at)), 'days').days <= 2
    )
  );

  thisWeekNotifications = computed<Notification[]>(() =>
    this.notifications().filter(
      notification =>
        DateTime.now().diff(DateTime.fromJSDate(new Date(notification.created_at)), 'days').days > 2 &&
        DateTime.now().diff(DateTime.fromJSDate(new Date(notification.created_at)), 'days').days <= 7
    )
  );

  olderNotifications = computed<Notification[]>(() =>
    this.notifications().filter(notification => DateTime.now().diff(DateTime.fromJSDate(new Date(notification.created_at)), 'days').days > 7)
  );

  markAllAsRead(isOnlyUnread: WritableSignal<boolean>): void {
    this.notificationsService
      .completeAllNotifications()
      .pipe(
        mergeMap(() => {
          isOnlyUnread.set(false);
          this.previousOnlyUnread.set(false);
          this.notifications.set([]);

          this.toastService.success('_Notifications.All notifications marked as read');

          this.loading.set(true);

          return this.notificationsService
            .getNotifications(
              new HttpParams({
                fromObject: {
                  page: 1,
                  per_page: this.notificationsResponse().meta.per_page,
                },
              })
            )
            .pipe(takeUntilDestroyed(this.destroyRef));
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (response: GetNotificationsResponse) => {
          this.notificationsResponse.update(() => response);
          this.notifications.update(prevData => [...prevData, ...response.data]);
        },
        error: (response: HttpErrorResponse) => this.errorService.handleError(response),
        complete: () => this.loading.set(false),
      });
  }

  markAsRead(notification: ModelSignal<Notification>): void {
    this.notificationsService
      .completeNotification(notification().id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          notification.update(() => ({ ...notification(), completed_at: new Date().toISOString() }));
          this.toastService.success('_Notifications.Notification has been marked as read');
        },
        error: (response: HttpErrorResponse) => this.errorService.handleError(response),
        complete: () => this.loading.set(false),
      });
  }

  getNotifications(page: number, onlyUnread: boolean): void {
    this.loading.set(true);

    if (!(this.previousOnlyUnread() === onlyUnread)) {
      this.previousOnlyUnread.set(onlyUnread);
      this.notifications.set([]);
    }

    const params: {
      page: number;
      per_page: number;
      'filter[completed]'?: string;
    } = {
      page: page,
      per_page: this.notificationsResponse().meta.per_page,
    };

    onlyUnread && (params['filter[completed]'] = 'false');

    this.notificationsService
      .getNotifications(new HttpParams({ fromObject: params }))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: GetNotificationsResponse) => {
          this.notificationsResponse.update(() => response);
          this.notifications.update(prevData => [...prevData, ...response.data]);
          this.uncompletedNotificationsCount.set(response.uncompleted_notifications);
        },
        error: (response: HttpErrorResponse) => this.errorService.handleError(response),
        complete: () => this.loading.set(false),
      });
  }

  listenForWebSocketEvents(): void {
    const userId = this.storageService.getUser()?.id!;

    this.echo.listen(`${EchoChannel.NOTIFICATIONS}.${userId}`, EchoEvent.NOTIFICATION_CREATED, (echoEventData: EchoEventData<Notification>) => {
      this.notifications.update(prevData => [echoEventData.data!, ...prevData]);
    });

    this.echo.listen(`${EchoChannel.NOTIFICATIONS}.${userId}`, EchoEvent.NOTIFICATION_COMPLETED, (echoEventData: EchoEventData<Notification>) => {
      this.notifications.update(prevData => prevData.map(notification => (notification.id === echoEventData.data!.id ? echoEventData.data! : notification)));
    });

    this.echo.listen(`${EchoChannel.NOTIFICATIONS}.${userId}`, EchoEvent.NOTIFICATION_UNCOMPLETED_COUNT_CHANGED, (echoEventData: EchoEventData<number>) => {
      this.uncompletedNotificationsCount.set(echoEventData.data!);
    });
  }
}
