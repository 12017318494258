import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { listenGlobalEcho } from '@helpers/echo-cache.helper';
import { ConfirmationDialogComponent } from '@layouts/dashboard-layout/_components/confirmation-dialog/confirmation-dialog.component';
import { NavbarComponent } from '@layouts/dashboard-layout/_components/navbar/navbar.component';
import { SidebarComponent } from '@layouts/dashboard-layout/_components/sidebar/sidebar.component';
import { NgIcon } from '@ng-icons/core';
import { EchoService } from '@services/echo.service';
import { StorageService } from '@services/storage.service';
import { ToastService } from '@services/toast.service';
import { PrimeTemplate } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DashboardLayoutService } from './_services/dashboard-layout.service';

@Component({
  selector: 'app-layouts',
  standalone: true,
  imports: [SidebarComponent, RouterOutlet, NavbarComponent, ConfirmDialogModule, PrimeTemplate, NgIcon, ConfirmationDialogComponent],
  templateUrl: './dashboard-layout.component.html',
  styleUrl: './dashboard-layout.component.scss',
})
export class DashboardLayoutComponent implements OnInit {
  private readonly echo = inject(EchoService);
  private readonly storageService = inject(StorageService);
  private readonly dashboardLayoutService = inject(DashboardLayoutService);
  private readonly toastService = inject(ToastService);

  ngOnInit(): void {
    listenGlobalEcho(
      this.echo,
      this.toastService,
      () => this.storageService.getUser(),
      () => this.dashboardLayoutService.logoutUser()
    );
  }
}
