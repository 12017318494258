<div id="layout">
  <div id="layout__sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div id="layout__content">
    <app-navbar></app-navbar>
    <router-outlet></router-outlet>
  </div>
</div>
<app-confirmation-dialog />
