import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { CONFIRMATION_DIALOG_CONFIG } from '@layouts/dashboard-layout/_configs/confirmation-dialog.config';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  imports: [ConfirmDialogModule, NgIcon, TranslocoPipe, ButtonModule],
  providers: [provideIcons(CONFIRMATION_DIALOG_CONFIG.icons)],
  templateUrl: './confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ConfirmationDialogComponent {}
