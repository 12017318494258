import { ChangeDetectionStrategy, Component, computed, inject, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoPipe } from '@jsverse/transloco';
import { UserNotificationInfiniteScrollComponent } from '@layouts/dashboard-layout/_components/user-notification-infinite-scroll/user-notification-infinite-scroll.component';
import { UserNotificationComponent } from '@layouts/dashboard-layout/_components/user-notification/user-notification.component';
import { USER_NOTIFICATIONS_CONFIG } from '@layouts/dashboard-layout/_configs/user-notifications.config';
import { UserNotificationsService } from '@layouts/dashboard-layout/_services/user-notifications.service';
import { NgIcon, provideIcons } from '@ng-icons/core';
import { tablerBellRinging, tablerLoader2 } from '@ng-icons/tabler-icons';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressBarModule } from 'primeng/progressbar';

@Component({
  selector: 'app-user-notifications',
  standalone: true,
  imports: [
    OverlayPanelModule,
    ButtonModule,
    NgIcon,
    TranslocoPipe,
    InputSwitchModule,
    FormsModule,
    UserNotificationComponent,
    InfiniteScrollDirective,
    UserNotificationInfiniteScrollComponent,
    ProgressBarModule,
  ],
  templateUrl: './user-notifications.component.html',
  styleUrl: './user-notifications.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideIcons({ tablerBellRinging, tablerLoader2 }), UserNotificationsService],
})
export class UserNotificationsComponent implements OnInit {
  protected readonly userNotificationsService = inject(UserNotificationsService);

  isOnlyUnread = signal<boolean>(USER_NOTIFICATIONS_CONFIG.isOnlyUnreadChecked);
  isButtonActive = signal<boolean>(false);

  buttonClasses = computed(() => `p-button-custom p-button-custom--notifications ${this.isButtonActive() ? 'p-button-custom--notifications-active' : ''}`);

  ngOnInit(): void {
    this.userNotificationsService.getNotifications(1, this.isOnlyUnread());
    this.userNotificationsService.listenForWebSocketEvents();
  }

  onOnlyUnreadChange(event: boolean): void {
    this.userNotificationsService.getNotifications(1, event);
  }
}
