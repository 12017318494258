import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { buildApiUrl } from '@helpers/environment.helper';
import { environment } from '@environment';
import { GetNotificationsResponse } from 'types/responses/get-notifications-response.type';

const NOTIFICATIONS_PATH = 'v1/notifications';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private readonly http = inject(HttpClient);

  getNotifications(params: HttpParams): Observable<GetNotificationsResponse> {
    return this.http
      .get<GetNotificationsResponse>(`${buildApiUrl(environment.api)}/${NOTIFICATIONS_PATH}`, { params })
      .pipe(map(response => response as GetNotificationsResponse));
  }

  completeNotification(id: number): Observable<unknown> {
    return this.http.put<void>(`${buildApiUrl(environment.api)}/${NOTIFICATIONS_PATH}/${id}/complete`, {}).pipe(map(response => response as unknown));
  }

  completeAllNotifications(): Observable<unknown> {
    return this.http.put<void>(`${buildApiUrl(environment.api)}/${NOTIFICATIONS_PATH}/complete`, {}).pipe(map(response => response as unknown));
  }
}
