import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AppRoutes } from '@configs/routes.config';
import { stopListeningGlobalEcho } from '@helpers/echo-cache.helper';
import { AuthService } from '@services/auth.service';
import { EchoService } from '@services/echo.service';
import { ErrorService } from '@services/error.service';
import { StorageService } from '@services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardLayoutService {
  private readonly destroy = inject(DestroyRef);
  readonly storageService = inject(StorageService);
  readonly authService = inject(AuthService);
  readonly router = inject(Router);
  readonly echo = inject(EchoService);
  readonly errorService = inject(ErrorService);

  logoutUser(): void {
    this.authService
      .logout()
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe({
        next: () => this.handleLogoutSuccess(),
        error: (response: HttpErrorResponse) => this.handleLogoutError(response),
        complete: () => this.handleLogoutComplete(),
      });
  }

  handleLogoutSuccess(): void {
    this.storageService.purge();
    this.echo.disconnect();
    stopListeningGlobalEcho(this.echo);
  }

  handleLogoutError(error: HttpErrorResponse): void {
    this.errorService.throwError(error);

    if (error.status === HttpStatusCode.Unauthorized) {
      this.handleLogoutSuccess();
      this.router.navigate([AppRoutes.Default, AppRoutes.Auth, AppRoutes.Login]);
    }
  }

  handleLogoutComplete(): void {
    this.router.navigate([AppRoutes.Default, AppRoutes.Auth, AppRoutes.Login]);
  }
}
