<div
  class="notifications-panel__infinite-scroll"
  infiniteScroll
  [scrollWindow]="false"
  (scrolled)="onScroll()"
  [class.notifications-panel__infinite-scroll--loading]="userNotificationsService.loading()">
  @if (userNotificationsService.notifications().length > 0) {
    @if (userNotificationsService.todayNotifications().length > 0) {
      <div class="notifications-panel__infinite-scroll__section">
        <div class="notifications-panel__infinite-scroll__section-title">{{ '_Notifications.Today' | transloco }}</div>
        <div class="notifications-panel__infinite-scroll__section-items">
          @for (notification of userNotificationsService.todayNotifications(); track $index) {
            <app-user-notification [notification]="notification" />
          }
        </div>
      </div>
    }
    @if (userNotificationsService.yesterdayNotifications().length > 0) {
      <div class="notifications-panel__infinite-scroll__section">
        <div class="notifications-panel__infinite-scroll__section-title">{{ '_Notifications.Yesterday' | transloco }}</div>
        <div class="notifications-panel__infinite-scroll__section-items">
          @for (notification of userNotificationsService.yesterdayNotifications(); track $index) {
            <app-user-notification [notification]="notification" />
          }
        </div>
      </div>
    }
    @if (userNotificationsService.thisWeekNotifications().length > 0) {
      <div class="notifications-panel__infinite-scroll__section">
        <div class="notifications-panel__infinite-scroll__section-title">{{ '_Notifications.This week' | transloco }}</div>
        <div class="notifications-panel__infinite-scroll__section-items">
          @for (notification of userNotificationsService.thisWeekNotifications(); track $index) {
            <app-user-notification [notification]="notification" />
          }
        </div>
      </div>
    }
    @if (userNotificationsService.olderNotifications().length > 0) {
      <div class="notifications-panel__infinite-scroll__section">
        <div class="notifications-panel__infinite-scroll__section-title">{{ '_Notifications.Older' | transloco }}</div>
        <div class="notifications-panel__infinite-scroll__section-items">
          @for (notification of userNotificationsService.olderNotifications(); track $index) {
            <app-user-notification [notification]="notification" />
          }
        </div>
      </div>
    }
  } @else if (!userNotificationsService.loading()) {
    <div class="notifications-panel__infinite-scroll__no-data-wrapper">
      <app-no-data message="_NoData.No content to display" />
    </div>
  }
</div>
