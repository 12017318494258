import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { TranslocoPipe } from '@jsverse/transloco';
import { UserNotificationComponent } from '@layouts/dashboard-layout/_components/user-notification/user-notification.component';
import { UserNotificationsService } from '@layouts/dashboard-layout/_services/user-notifications.service';
import { NoDataComponent } from '@components/no-data/no-data.component';

@Component({
  selector: 'app-user-notification-infinite-scroll',
  standalone: true,
  imports: [InfiniteScrollDirective, TranslocoPipe, UserNotificationComponent, NoDataComponent],
  templateUrl: './user-notification-infinite-scroll.component.html',
  styleUrl: './user-notification-infinite-scroll.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNotificationInfiniteScrollComponent {
  protected readonly userNotificationsService = inject(UserNotificationsService);

  isOnlyUnread = input.required<boolean>();

  onScroll(): void {
    const meta = this.userNotificationsService.notificationsResponse().meta;

    if (!(meta.current_page === meta.last_page)) {
      this.userNotificationsService.getNotifications(meta.current_page + 1, this.isOnlyUnread());
    }
  }
}
