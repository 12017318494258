<div class="navbar">
  <div class="navbar__wrapper">
    <h1 data-cy="navbar-title">{{ '_DashboardLayoutSidebar.' + title() | transloco }}</h1>
    <div class="navbar__side">
      @if (storageService.user(); as user) {
        <app-start-stop />
        <app-user-notifications />
        <app-user-avatar size="large" [user]="user" />
      }
    </div>
  </div>
</div>
