import { Component, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivationEnd, Router } from '@angular/router';
import { UserAvatarComponent } from '@components/user-avatar/user-avatar.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { StorageService } from '@services/storage.service';
import { StartStopComponent } from '../start-stop/start-stop.component';
import { UserNotificationsComponent } from '@layouts/dashboard-layout/_components/user-notifications/user-notifications.component';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [TranslocoPipe, UserAvatarComponent, StartStopComponent, UserNotificationsComponent],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent {
  protected readonly storageService = inject(StorageService);
  private readonly router = inject(Router);
  protected readonly title = signal<string>('');

  constructor() {
    this.router.events.pipe(takeUntilDestroyed()).subscribe(event => {
      if (event instanceof ActivationEnd && event.snapshot.title) this.title.set(event.snapshot.title);
    });
  }
}
