import { tablerExclamationMark, tablerQuestionMark, tablerX } from '@ng-icons/tabler-icons';

export const CONFIRMATION_DIALOG_CONFIG: {
  icons: {
    [key: string]: string;
  };
} = {
  icons: {
    tablerQuestionMark,
    tablerExclamationMark,
    tablerX,
  },
};
