import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Agent } from '@models/agent.model';
import { AgentsService } from '@services/agents.service';
import { ErrorService } from '@services/error.service';
import { StorageService } from '@services/storage.service';

@Injectable()
export class StartStopService {
  private readonly agentsService = inject(AgentsService);
  private readonly errorService = inject(ErrorService);
  readonly storageService = inject(StorageService);

  started = signal<boolean>(false);
  processing = signal<boolean>(false);

  toggleWork(): void {
    this.started() ? this.stop() : this.start();
  }

  start(): void {
    this.processing.set(true);
    this.agentsService.startWork().subscribe({
      next: () => {
        this.updateUser(true);
        this.started.set(true);
        this.processing.set(false);
      },
      error: (response: HttpErrorResponse) => {
        this.processing.set(false);
        this.errorService.throwError(response);
      },
    });
  }

  stop(): void {
    this.processing.set(true);
    this.agentsService.stopWork().subscribe({
      next: () => {
        this.updateUser(false);
        this.started.set(false);
        this.processing.set(false);
      },
      error: (response: HttpErrorResponse) => {
        this.processing.set(false);
        this.errorService.throwError(response);
      },
    });
  }

  updateUser(started: boolean): void {
    const user = this.storageService.getUser() as Agent;
    if (user) {
      user.start = started ? new Date().toISOString() : null;
      user.stop = started ? null : new Date().toISOString();
    }
    const newUser = new Agent(user);
    this.storageService.saveUser(newUser);
    this.storageService.user.set(newUser);
  }
}
