<div class="sidebar">
  <div class="sidebar__brand-name">
    <app-brand-name />
  </div>
  <nav class="sidebar__navigation">
    <ul class="navigation__list">
      @for (item of navigationListItems; track $index) {
        <app-navigation-list-item [item]="item" />
      }
    </ul>
  </nav>
  <nav class="sidebar__navigation">
    <ul class="navigation__list">
      <app-navigation-list-item (click)="dashboardLayoutService.logoutUser()" [item]="logoutNavigationItem!" />
    </ul>
  </nav>
</div>
